<template>
  <div>

     <div class="breadCurmb mt-3">
      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("Resturant") }}</label>
      <span>/</span>
      <label>
        <router-link :to="{name: 'menu'}">{{ $t("CusineMenu") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $route.params.id? $t("Edit"): $t('Add') }}</label>

    </div>

    <v-toolbar-title class="mt-3">{{
      $route.params.id ? $t("EditMenu") : $t("AddNewMenu")
    }}</v-toolbar-title>

   <template>
     <v-tabs class="mt-2" v-model="activeName" background-color="primary" dark >
      <v-tab>{{ $t("PrimaryData") }}</v-tab>
      <v-tab>مكونات المنيو الأساسية</v-tab>
      <v-tab>إضافة المميزات مع الوصفات</v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeName">
     <div>
       <v-tab-item>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col class="py-0" :cols="4">
                <label>{{$t('CookingDate')}}</label>
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.cooking_date`),
                  }"
                >
                  <el-date-picker
                    v-model="formData.cooking_date"
                    type="date"
                    style="width:100%;"
                  >
                  </el-date-picker>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.cooking_date`)"
                >
                  {{ errors.first(`addEditValidation.cooking_date`) }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-tab-item>

      
      <v-tab-item>
        <v-container fluid>
          <div class="mt-5">
            <el-button @click="addNew" type="primary float-right mb-5">
              إضافة مكونات المنيو الأساسية
            </el-button>
          </div>

           <table id="pacakgeFeaturesTable">
            <tr>
              <th>#</th>
              <th>المكون الأساسي</th>
              <th>وصف البروتين</th>
              <th>المطبخ</th>
              <th>وصف الكارب</th>
              <th>المطبخ</th>
              <th>العمليات</th>
            </tr>

            <tr v-for="(l, lIndex) in formData.first_group" :key="lIndex">
              <td>
              {{  lIndex + 1}}
              </td>
              <td>
                <div
              :class="{
                'has-error': errors.has(`addEditValidation.main_ingredients${lIndex}`),
              }"
            >
            <label>{{$t('MainIngredient')}}</label>
              <v-autocomplete
                class="d-block my-2"
                :name="`main_ingredients${lIndex}`"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('MainIngredient')"
                hide-details
                dense
                outlined
                v-model="l.ingredient_id"
                item-value="id"
                item-text="name"
                :items="listMainIngredients"
                clearable
                @input="changeListProteinRecipie(l);changeListCarbRecipie(l)"
              ></v-autocomplete>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.main_ingredients${lIndex}`)"
            >
              {{ errors.first(`addEditValidation.main_ingredients${lIndex}`) }}
            </div>

              </td>

                <td>
                <div
              :class="{
                'has-error': errors.has(`addEditValidation.protein_recipie${lIndex}`),
              }"
            >
            <label>{{$t('ProteinRecipie')}}</label>
              <v-autocomplete
                class="d-block my-2"
                :name="`protein_recipie${lIndex}`"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('ProteinRecipie')"
                hide-details
                dense
                outlined
                v-model="l.recipie_protein_id"
                item-value="id"
                item-text="name"
                :items="l.recipie_protein_list"
                clearable
              ></v-autocomplete>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.protein_recipie${lIndex}`)"
            >
              {{ errors.first(`addEditValidation.protein_recipie${lIndex}`) }}
            </div>

              </td>


               <td>
                <div
              :class="{
                'has-error': errors.has(`addEditValidation.cuisine_protein_id${lIndex}`),
              }"
            >
            <label>{{$t('Cuisine')}}</label>
              <v-autocomplete
                class="d-block my-2"
                :name="`cuisine_protein_id${lIndex}`"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('Cuisine')"
                hide-details
                dense
                outlined
                v-model="l.cuisine_protein_id"
                item-value="id"
                item-text="name"
                :items="listCuisine"
                clearable
              ></v-autocomplete>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.cuisine_protein_id${lIndex}`)"
            >
              {{ errors.first(`addEditValidation.cuisine_protein_id${lIndex}`) }}
            </div>

              </td>



                   <td>
                <div
              :class="{
                'has-error': errors.has(`addEditValidation.recipie_carb_id2${lIndex}`),
              }"
            >
             <label>{{$t('CarbRecipie')}}</label>
              <v-autocomplete
                class="d-block my-2"
                :name="`recipie_carb_id2${lIndex}`"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('CarbRecipie')"
                hide-details
                dense
                outlined
                v-model="l.recipie_carb_id"
                item-value="id"
                item-text="name"
                :items="l.recipie_carb_list"
                clearable
              ></v-autocomplete>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.recipie_carb_id2${lIndex}`)"
            >
              {{ errors.first(`addEditValidation.recipie_carb_id2${lIndex}`) }}
            </div>

              </td>

              
               <td>
                <div
              :class="{
                'has-error': errors.has(`addEditValidation.cuisine_carb_id2${lIndex}`),
              }"
            >
            <label>{{$t('Cuisine')}}</label>
              <v-autocomplete
                class="d-block my-2"
                :name="`cuisine_carb_id2${lIndex}`"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('Cuisine')"
                hide-details
                dense
                outlined
                v-model="l.cuisine_carb_id"
                item-value="id"
                item-text="name"
                :items="listCuisine"
                clearable
              ></v-autocomplete>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.cuisine_carb_id2${lIndex}`)"
            >
              {{ errors.first(`addEditValidation.cuisine_carb_id2${lIndex}`) }}
            </div>

              </td>
              




              <td>

                  <v-icon
          class="my-0 mr-2 ml-2"
          dense
          @click="
            removeRow(lIndex);
          "
          color="danger"
          >mdi-delete</v-icon
        >

              </td>

            </tr>

           </table>
        </v-container>
      </v-tab-item>


        <v-tab-item>
        <v-container fluid>
          <div class="mt-5">
            <el-button @click="addNew2" type="primary float-right mb-5">
              إضافة المميزات مع الوصفات
            </el-button>
          </div>

           <table id="pacakgeFeaturesTable">
            <tr>
              <th>#</th>
              <th>الميزة</th>
              <th>الوصفة</th>
              <th>المطبخ</th>
              <th>العمليات</th>
            </tr>

            <tr v-for="(l2, lIndex2) in formData.second_group" :key="lIndex2">
              <td>
              {{  lIndex2 + 1}}
              </td>
              <td>
                <div
              :class="{
                'has-error': errors.has(`addEditValidation.group_id${lIndex2}`),
              }"
            >
            <label>{{$t('Group')}}</label>
              <v-autocomplete
                class="d-block my-2"
                :name="`group_id${lIndex2}`"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('Group')"
                hide-details
                dense
                outlined
                v-model="l2.group_id"
                item-value="id"
                item-text="name"
                :items="listGroup"
                clearable
                @input="changeListGroupRecipie(l2);"
              ></v-autocomplete>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.group_id${lIndex2}`)"
            >
              {{ errors.first(`addEditValidation.group_id${lIndex2}`) }}
            </div>

              </td>

                <td>
                <div
              :class="{
                'has-error': errors.has(`addEditValidation.recipie_id`),
              }"
            >
            <label>{{$t('Recipie')}}</label>
              <v-autocomplete
                class="d-block my-2"
                name="protein_recipie"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('Recipie')"
                hide-details
                dense
                outlined
                v-model="l2.recipie_id"
                item-value="id"
                item-text="name"
                :items.sync="l2.recipie_group_list"
                clearable
              ></v-autocomplete>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.recipie_id`)"
            >
              {{ errors.first(`addEditValidation.recipie_id`) }}
            </div>

              </td>


               <td>
                <div
              :class="{
                'has-error': errors.has(`addEditValidation.cuisine_protein_id${lIndex2}`),
              }"
            >
            <label>{{$t('Cuisine')}}</label>
              <v-autocomplete
                class="d-block my-2"
                :name="`cuisine_protein_id${lIndex2}`"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('Cuisine')"
                hide-details
                dense
                outlined
                v-model="l2.cuisine_id"
                item-value="id"
                item-text="name"
                :items="listCuisine"
                clearable
              ></v-autocomplete>
            </div>

            <div
              class="help-block"
              v-if="errors.has(`addEditValidation.cuisine_protein_id${lIndex2}`)"
            >
              {{ errors.first(`addEditValidation.cuisine_protein_id${lIndex2}`) }}
            </div>

              </td>

              <td>

                  <v-icon
          class="my-0 mr-2 ml-2"
          dense
          @click="
            removeRow2(lIndex2);
          "
          color="danger"
          >mdi-delete</v-icon
        >

              </td>

            </tr>

           </table>
        </v-container>
      </v-tab-item>

     </div>


    </v-tabs-items>

    <v-container fluid>
      <v-row class="float-left">
        <v-btn
          class="mr-2 ml-5"
          color="success"
          dark
          outlined
          small
          @click="save"
        >
          {{ $t("Save") }}
        </v-btn>
      </v-row>
    </v-container>
    
   </template>

       <BlockUI v-if ="loadClicked" :message="msg">
        <div class="sk-wave">
          <div class="sk-rect sk-rect1"></div>
          <div class="sk-rect sk-rect2"></div>
          <div class="sk-rect sk-rect3"></div>
          <div class="sk-rect sk-rect4"></div>
          <div class="sk-rect sk-rect5"></div>
        </div>
      </BlockUI>

  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: "menuAddEdit",
  data() {
    return {
             user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : [],
      activeName: null,
       loadClicked: false,
       msg: 'برجاء الانتظار لحين اكتمال العملية',
      formData: {
        id: 0,
        cooking_date: null,
        first_group: [],
        second_group: [],
      },
      listGroup: [],
      listMainIngredients: [],
      listCuisine: [],
    };
  },
  methods: {
    removeRow(index) {
      this.formData.first_group.splice(index, 1);
    },
    removeRow2(index) {
      this.formData.second_group.splice(index, 1);
    },
    changeListProteinRecipie(data) {
      if(data.ingredient_id) {
        data.recipie_protein_list = [];
        this.$store.dispatch(`menu/listProteinRecipies`, data.ingredient_id).then((res) => {
          data.recipie_protein_list = res.data;
      });
    }
    },
    changeListCarbRecipie(data) {
      if(data.ingredient_id) {
        data.recipie_carb_list = [];
      this.$store.dispatch(`menu/listCarbRecipies`, data.ingredient_id).then((res) => {
        data.recipie_carb_list = res.data;
      });
    }
    },
    changeListGroupRecipie(data) {
      if(data.group_id) {
      data.recipie_group_list= [];
      this.$store.dispatch(`menu/listRecipiesOfGroup`, data.group_id).then((res) => {
        data.recipie_group_list = res.data;
    });
    }
    },
    addNew() {
      let listObj = {
        ingredient_id: null,
        recipie_protein_id: null,
        cuisine_protein_id: null,
        recipie_carb_id: null,
        cuisine_carb_id: null,
        
        recipie_protein_list: [],
        recipie_carb_list: [],
        recipie_group_list: [],
      };
      this.formData.first_group.push(listObj);
    },
    addNew2() {
      let listObj = {
        group_id: null,
        recipie_id: null,
        cuisine_id: null,
        recipie_group_list:[],
      };
      this.formData.second_group.push(listObj);
    },
    save() {
      this.loadClicked= true;
      let sendData= JSON.parse(JSON.stringify(this.formData));

        if (this.formData.cooking_date)
          sendData.cooking_date = moment(
            sendData.cooking_date
          ).format("YYYY-MM-DD");
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) {
           this.loadClicked= false;
          return this.notifyCustomError("Error", "ValidationsErrors");
        }
        if (this.$route.params.id) {
          //edit
          this.$store.dispatch(`menu/updateData`, sendData).then((res) => {
            this.notifySuccess("Update", res);
             this.loadClicked= false;
            this.$router.push({ name: "menu" });
          }).finally(() => {
             this.loadClicked= false;
          })
        } else {
          this.$store.dispatch(`menu/saveData`, sendData).then((res) => {
            this.notifySuccess("Save", res);
             this.loadClicked= false;
            this.$router.push({ name: "menu" });
          }).finally(() => {
             this.loadClicked= false;
          })
        }
      });
    },
    initData() {
      Promise.all(
        [
        this.$store.dispatch(`menu/listCuisine`),
        this.$store.dispatch(`menu/listMainIngredients`),
        this.$store.dispatch(`menu/listGroup`),
        ]
        ).then(res => {
         this.listCuisine = res[0].data;
         this.listMainIngredients = res[1].data;
         this.listGroup = res[2].data;
        });

       if (this.$route.params.id) {

         const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
			Promise.all(
        [
         this.$store
        .dispatch(`menu/findData`, this.$route.params.id),
        ]
        ).then(res => {
          res[0].data.first_group.forEach(d => {
            this.changeListProteinRecipie(d);
            this.changeListCarbRecipie(d);
          });
          res[0].data.second_group.forEach(e => {
            this.changeListGroupRecipie(e);
          });

         this.formData = res[0].data;
          
          
          setTimeout(() => {
            loading.close();
          }, 500);

        })
      }
    },
  },
  created() {
       if(this.$route && this.$route.params && this.$route.params.id
      && this.user &&
        this.user.permissions &&
        !this.user.permissions.includes("update_menu"))
        {
          this.$router.push({name:'noPermission'})
        }
    else if( this.user &&
        this.user.permissions &&
        !this.user.permissions.includes("store_menu"))
        {
          this.$router.push({name:'noPermission'})
        }
   else {
     this.initData();
   }     

  },
};
</script>

<style>
#pacakgeFeaturesTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#pacakgeFeaturesTable td,
#pacakgeFeaturesTable th {
  border: 1px solid #ccc !important;
  padding: 8px;
}

#pacakgeFeaturesTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: right;
  background-color: #04aa6d;
  color: white;
}
</style>